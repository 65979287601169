@import "./utils/colors";

.Meeting__App {
  .Meeting__container {
    .hrLine {
      left: 0;
      margin: 5px 0 8px 0;
      height: 1px;
      overflow: hidden;
      position: relative;
      background: #c9cacb;
    }

    .error {
      margin-bottom: 30px;
      text-align: center;
      font-weight: 400;
      color: $black;
    }
  }
}
