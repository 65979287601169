@import "../../utils/colors";

.container {
  .content {
    margin-bottom: 15px;

    .btns {
      margin-top: 30px;
      display: flex;
      justify-content: center;

      > div {
        margin: 0 40px;
      }
    }

    .description {
      margin-bottom: 10px;
    }

    textarea {
      padding: 4px 10px 5px 10px;
      color: #525252;
      font-size: 12px;
      outline: none;
      border: 1px solid #cdc8c8;
      box-shadow: 0 0 10px #dcdcdd;
      border-radius: 0px;
    }
  }
}
