@import "../../../utils/colors";

.container {
  .content {
    .buttonWrap {
      text-align: center;

      button {
        width: 240px;
        height: 40px;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 700;
        color: $white;
        border: 0;
      }

      &.azure {
        :global(.ant-btn-primary) {
          background: linear-gradient(
            to bottom,
            lighten($azure, 8%) 0%,
            lighten($azure, 3) 7%,
            $azure 14%,
            darken($azure, 3) 49%,
            $azure 78%,
            lighten($azure, 3) 86%,
            lighten($azure, 8%) 100%
          );
        }
      }

      &.red {
        :global(.ant-btn-primary) {
          background: linear-gradient(
            to bottom,
            lighten($red, 8%) 0%,
            lighten($red, 3) 7%,
            $red 14%,
            darken($red, 3) 49%,
            $red 78%,
            lighten($red, 3) 86%,
            lighten($red, 8%) 100%
          );
        }
      }

      &.green {
        :global(.ant-btn-primary) {
          background: linear-gradient(
            to bottom,
            lighten($green, 8%) 0%,
            lighten($green, 3) 7%,
            $green 14%,
            darken($green, 3) 49%,
            $green 78%,
            lighten($green, 3) 86%,
            lighten($green, 8%) 100%
          );
        }
      }

      &.blue {
        :global(.ant-btn-primary) {
          background: linear-gradient(
            to bottom,
            lighten($blue, 8%) 0%,
            lighten($blue, 3) 7%,
            $blue 14%,
            darken($blue, 3) 49%,
            $blue 78%,
            lighten($blue, 3) 86%,
            lighten($blue, 8%) 100%
          );
        }
      }
    }
  }
}
