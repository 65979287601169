$black: #000000;
$white: #ffffff;
$darkGray: #666666;
$gray: #eeeeee;
$grayTitle: #333333;
$azure: #5bc0de;
$red: #d9534f;
$green: #5cb85c;
$blue: #428bca;
$blue: #6185ac;
