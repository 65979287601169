@import "../../utils/colors";

.container {
  .content {
    margin: 16px 0 15px 0;
    min-height: 400px;
    border-radius: 3px;
    width: 500px;

    .title {
      h6 {
        text-align: center;
        font-size: 28px;
        letter-spacing: -1px;
        color: $grayTitle;
        font-weight: 400;
      }
    }

    .description {
      margin-bottom: 30px;
      text-align: center;
      font-weight: 400;
      color: $black;
    }

    .meetInfo {
      margin-bottom: 18px;
      line-height: 26px;
      color: #525252;
      font-size: 12px;
      display: flex;

      p:first-child {
        text-transform: uppercase;
        padding-right: 9px;
        display: inline-block;
        width: 120px;
        text-align: left;
        color: $black;
        font-size: 14px;
        flex-shrink: 0;
        margin: 0;
      }

      p:last-child {
        flex-shrink: 2;
        margin: 0;
      }
    }

    .callToAction {
      margin-top: 30px;

      .wrap {
        p {
          text-align: center;
          font-weight: 400;
          color: $black;
        }

        .btns {
          margin-top: 30px;
        }
      }
    }
  }

  .meetingIsFinished {
    justify-content: center;
    display: flex;
    margin-top: 20px;
    font-size: 16px;
    background-color: $red;
    color: white;
    border-radius: 4px;
    padding: 4px;
  }
}
