.App {
  .container {
    display: flex;
    padding-top: 50px;
    justify-content: center;
    .content {
      padding: 15px 0;
    }
  }
}
