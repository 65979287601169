@import "../../../utils/colors";

.container {
  display: none;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }

  .shadow {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .content {
    position: absolute;
    z-index: 3;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $white;
    padding: 12px;
    border-radius: 2px;

    .wrap {
      padding: 10px 10px;
      border-radius: 5px 5px 0 0;
      border: 1px solid $darkGray;

      .header {
        width: 550px;

        p {
          color: $white;
          font-size: 20px;
          font-weight: 500;
        }
      }

      .info {
        margin-top: 24px;
      }
    }
  }
}
