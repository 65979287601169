@import "../../../utils/colors";

.container {
  .content {
    .firstLine {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      > div {
        margin: 0 10px;
      }
    }

    .secondLine {
      display: flex;
      justify-content: center;
    }
  }
}
