@import "utils/fonts";
@import "utils/colors";
@import "utils/reset-style";
@import "~antd/dist/antd.css";

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: $darkGray;
}

.ant-select-selection-placeholder {
  color: #dbdbdb;
}
